import React from "react"
import Layout from "../templates/layout"
import SEO from "../components/seo/seo"
import "../styles/pages/index.scss"


const pageContent = () => (

    <Layout pageid="page-404">
        <SEO
            title="Page not found"
            keywords="page, not, found"
            description="This is the 404 page.  The page you are looking for doesn't exist or an error has occurred."
        />
        <section className="four-o-four" id="">
            <figure className="hero-wave"></figure>
            <div className="content-section p-b-15">
                <h1>404</h1>
                <h2>Page not found</h2>
                <p>The page you are looking for doesn't exist or an error has occurred.</p>
                <p>Please go back to <a href="https://www.sanoficareassist.com" title="Visit the CareAssist website to choose a new treatment" target="_blank" rel="noreferrer">SanofiCareASSIST.com</a>.</p>
            </div>
        </section>

    </Layout>

)

export default pageContent